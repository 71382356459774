<template>
  <div class="page">
    <el-row>
      <el-col :span="14">
        <h2>全局批发</h2>
        <el-table :data="priceList">
          <el-table-column label="数量">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="价格">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price">
                <span slot="suffix">%</span>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-link :underline="false" type="danger" @click="priceList.splice(scope.$index,1)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-button @click="priceListAdd" style="width: 100%;margin-top: 5px">+</el-button>

        <div style="text-align: right;margin-top: 10px">
          <el-button type="primary" @click="submit">保存</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {apiOperationWholesale,apiGetGlobalWholesale} from "@/request/api";

export default {
  name: "globalWholesale",
  data() {
    return {
      priceList:[],
      id:null
    }
  },
  created() {
    this.getGlobalList()
  },
  methods:{
    priceListAdd(){
      this.priceList.push({number:null,price:null})
    },
    submit() {
      var list = JSON.parse(JSON.stringify(this.priceList))
      list.forEach(item => {
        item.price = item.price / 100
      })
      apiOperationWholesale({data:[{id:this.id,sku_id:0,kind:2,ladder_price:list}]}).then(res => {
        if(res.code == 200){
          this.id = res.data.id
          this.$message.success(res.message)
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getGlobalList() {
      apiGetGlobalWholesale().then(res => {
        if(res.code == 200) {
          var list = JSON.parse(res.data.ladder_price)
          list.forEach(item => {
            item.price = item.price * 100
          })
          console.log(list)
          this.priceList = list
          this.id = res.data.id
        }else{
          this.$message.error(res.message)
        }
      })
    }
  },
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
</style>
